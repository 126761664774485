import { apiAxios } from '../utils/request.js'
// 提交oss文件，返回文件ID
export const submitOssFile = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/file/submitOssFile', data, Istoast, Send)
}
// 文件列表
export const filePage = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/file/page', data, Istoast, Send)
}
// 删除文件
export const delFile = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/file/delFile', data, Istoast, Send)
}
// 编辑图片名称
export const updateFileInfo = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/file/updateFileInfo', data, Istoast, Send)
}
// 删除图片
export const removeFile = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/file/delFile', data, Istoast, Send)
}
// 标签列表
export const tagList = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/file/tag/list', data, Istoast, Send)
}
// 删除标签
export const tagRemove = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/file/tag/remove', data, Istoast, Send)
}
// 更新文件信息
export const fileUpdateFileInfo = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/file/updateFileInfo2', data, Istoast, Send)
}
// 提交oss文件，返回文件ID
export const submitOssFile2 = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/file/submitOssFile2', data, Istoast, Send)
}
// 修改标签名称
export const tagRename = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/file/tag/rename', data, Istoast, Send)
}
