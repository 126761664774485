<template>
  <div class="container">
    <p class="title">My Account</p>
    <div class="con">
      <a-alert
        v-if="isAlert"
        message="The verification link has been sent to your email"
        type="warning"
        closable
        :after-close="handleClose"
      />
      <div class="account-image">
        <img src="../../assets/img/my-account.png" alt>
      </div>
      <div class="account-msg account-form">
        <a-form-model
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          v-bind="layout"
        >
          <h6>Contact details</h6>
          <a-form-model-item has-feedback label="Full name" prop="fullName">
            <a-input
              v-model="ruleForm.fullName"
              size="large"
              type="text"
              autocomplete="off"
            />
          </a-form-model-item>
          <a-form-model-item has-feedback label="E-mail" prop="email">
            <a-input
              v-model="ruleForm.email"
              :disabled="isChange"
              size="large"
              type="text"
              autocomplete="off"
              :class="{ w270: emailConfirmStatus == 2 }"
            />
            <div v-if="verifyEmailError" class="tip">
              Account mailbox already exists
            </div>
            <!-- v-if="emailConfirmStatus == 2" -->
            <a-button type="primary" @click="verify">verify</a-button>
            <!-- v-if="emailConfirmStatus == 2" -->
            <a
              class="change"
              style="margin-left: 20px"
              @click="checkEmailChange(0)"
            >Change account email</a>
          </a-form-model-item>
          <a-form-model-item has-feedback label="Phone" prop="phone">
            <a-input
              v-model="ruleForm.phone"
              size="large"
              type="text"
              autocomplete="off"
            />
          </a-form-model-item>
          <a-form-model-item has-feedback label="standbyPhone" prop="Phone2">
            <a-input
              v-model="ruleForm.Phone2"
              size="large"
              type="text"
              autocomplete="off"
            />
          </a-form-model-item>
          <h6>Address</h6>
          <a-form-model-item has-feedback label="Country" prop="country">
            <a-select
              v-model="ruleForm.country"
              autocomplete="off"
              size="large"
              @change="choiceCountrys"
            >
              <a-select-option
                v-for="(item, index) in countrysList"
                :key="index"
                :value="item.id"
              >{{ item.enName }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            has-feedback
            label="state/Province"
            prop="province"
          >
            <a-input
              v-model="ruleForm.province"
              size="large"
              type="text"
              autocomplete="off"
            />
          </a-form-model-item>
          <a-form-model-item has-feedback label="Address1" prop="address1">
            <a-input
              v-model="ruleForm.address1"
              size="large"
              type="text"
              autocomplete="off"
            />
          </a-form-model-item>
          <a-form-model-item has-feedback label="Postal Code" prop="zipCode">
            <a-input
              v-model="ruleForm.zipCode"
              size="large"
              type="text"
              autocomplete="off"
            />
          </a-form-model-item>
          <a-form-model-item
            has-feedback
            label="Address(optional)"
            prop="address2"
          >
            <a-input
              v-model="ruleForm.address2"
              size="large"
              type="text"
              autocomplete="off"
            />
          </a-form-model-item>
          <a-form-model-item has-feedback label="City" prop="city">
            <a-input
              v-model="ruleForm.city"
              size="large"
              type="text"
              autocomplete="off"
            />
          </a-form-model-item>
        </a-form-model>
        <div class="actions">
          <a-button
            style="margin-right: 46px; margin-left:28px"
            size="large"
            type="primary"
            @click="submit"
          >Save</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { merchantInfo, editMerchantInfo } from '@/api/merchant'
import { countrys } from '@/api/public'
import { confirmSend, emailChange } from '@/api/user'
export default {
  data() {
    return {
      verifyEmailError: false,
      isChange: true,
      isEmailComfirm: false,
      isAlert: false,
      iconLoading: false,
      countrysList: [],
      countryId: 0, // 国家ID
      countryName: '', // 国家名称
      ruleForm: {
        zipCode: '',
        fullName: '',
        email: '',
        country: '',
        city: '',
        address2: '',
        address1: '',
        phone: '',
        phone2: '',
        province: ''
      },
      rules: {
        zipCode: [],
        email: [],
        fullName: [],
        country: [],
        city: [],
        address2: [],
        address1: [],
        phone: [],
        phone2: [],
        province: []
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 }
      }
    }
  },
  computed: {
    ...mapState({
      emailConfirmStatus: state => state.User.merchantInfo.emailConfirmStatus
    })
  },
  async mounted() {
    {
      const { data } = await countrys()
      this.countrysList = data.countryInfoList
    }
    this.getMerchantInfo()
  },
  methods: {
    async getMerchantInfo() {
      const { data } = await merchantInfo()
      this.ruleForm = data
      this.countryId = data.countryId
    },
    // 检测邮箱是否可以修改
    async checkEmailChange(val) {
      const { data, code } = await emailChange({ newEmail: this.ruleForm.email })
      if (code !== 200) {
        this.verifyEmailError = true
      } else if (val === 1 && code === 200) {
        this.sendEmail()
      }
      this.isChange = false
      console.log(data)
    },
    async verify() {
      const _this = this
      this.isAlert = true
      this.$confirm({
        title: '',
        content: 'A verification email has been sent to your mailbox',
        onOk() {
          return new Promise((resolve, reject) => {
            // 1发送邮件
            _this.checkEmailChange(1)
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000)
          }).catch(() => console.log('Oops errors!'))
        }
      })
    },
    // 发送邮箱
    async sendEmail() {
      const _this = this
      const { code, msg } = await confirmSend()
      if (code === 200) {
        setTimeout(function() {
          _this.isAlert = false
        }, 6000)
      } else {
        this.$message(msg)
      }
    },
    async submit() {
      const { code, msg } = await editMerchantInfo({
        zipCode: this.ruleForm.zipCode,
        fullName: this.ruleForm.fullName,
        email: this.ruleForm.email,
        country: this.ruleForm.country,
        countryId: this.countryId,
        city: this.ruleForm.city,
        address2: this.ruleForm.address2,
        address1: this.ruleForm.address1,
        phone: this.ruleForm.phone,
        phone2: this.ruleForm.phone2,
        province: this.ruleForm.province
      })
      if (code === 200) {
        this.$message.success('success')
      } else {
        this.$message.error(msg)
      }
    },
    // 选择国家
    async choiceCountrys(e) {
      console.log(e)
      this.countryId = e
      for (let i = 0; i < this.countrysList.length; i++) {
        if (this.countrysList[i]['id'] === e) {
          this.countryName = this.countrysList[i]['enName']
        }
      }
    },
    handleClose() {
      console.log('close')
    }
  }
}
</script>
<style scoped lang="less">
.w270 {
  width: 270px;
}
.tip {
  font-size: 12px;
  color: #e32;
  line-height: 2;
}
.change {
  font-size: 12px;
  color: #106ebe;
  text-decoration: underline;
  cursor: pointer;
}
.my-account .container {
  float: right;
  max-width: 884px;
  vertical-align: top;
  padding: 16px;
  font-size: 1rem;
  position: relative;
  margin-bottom: 16px;
  box-sizing: border-box;
}
.my-account .container .title {
  font-family: CerebriSans, sans-serif;
  font-weight: 500;
  line-height: 1.19;
  font-size: 33px;
  letter-spacing: -1px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}
.my-account .con {
  position: relative;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(133, 123, 123, 0.25),
    0 2px 3px hsla(0, 0%, 58%, 0.15), 0 0 2px hsla(0, 0%, 58%, 0.1);
  background-color: #fff;
  color: #383838;
  text-align: left;
  padding: 8px 16px;
  font-size: 0;
  box-sizing: border-box;
}
.my-account .account-image {
  display: inline-block;
  width: 33%;
  vertical-align: top;
  margin-top: 20px;
}
.my-account .account-image img {
  width: 100%;
  padding: 0 60px;
  box-sizing: border-box;
}
.my-account .account-msg {
  width: 66.66666667%;
  font-size: 16px;
  display: inline-block;
}
.my-account .account-form {
  margin-bottom: 30px;
}
.my-account .account-form h6 {
  margin: 12px 0 0;
  font-weight: 600;
  font-size: 18px;
}
.my-account .form-message {
  margin-bottom: 25px;
  line-height: 16px;
  font-size: 13px;
  color: rgba(56, 56, 56, 0.5);
}
.my-account .input-label-wrap {
  margin: 20px 0 0;
  position: relative;
}
.my-account .input-label-wrap-select {
  display: inline-block;
  width: 48%;
}
.my-account .input-label-wrap-marleft {
  margin-left: 4%;
}
.my-account .input-label-wrap input,
select {
  margin-bottom: 0;
  width: 100%;
  max-width: 100%;
  height: 44px;
  min-height: 44px;
  padding: 13px;
  line-height: 13px;
  font: inherit;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  outline: none;
  background-color: #fff;
  box-sizing: border-box;
  cursor: text;
  border-radius: 5px;
}

/* .my-account .input-label-wrap input:focus + label {
  top: -6px;
  font-size: 12px;
} */
.my-account .input-label-wrap label {
  position: absolute;
  left: 10px;
  padding: 0 4px;
  line-height: 1;
  background: #fff;
  white-space: nowrap;
  top: 14px;
  color: rgba(56, 56, 56, 0.5);
  font-size: 16px;
  cursor: text;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear; /* Safari */
}
.my-account .input-label-wrap .selected::after {
  color: #e32;
  content: " *";
  display: inline;
}
.my-account .input-label-wrap span {
  position: absolute;
  top: 44px;
  left: 14px;
  line-height: 18px;
  text-align: left;
  color: rgba(56, 56, 56, 0.5);
  font-size: 14px;
}
.my-account .actions {
  margin: 30px 0 20px;
  text-align: right;
}
.my-account .button-normal {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  font-weight: 500;
  padding: 12px 20px;
  border-radius: 4px;
  width: 140px;
  text-align: center;
  font-size: 18px;
  color: rgba(56, 56, 56, 0.6);
  border: 1px solid rgba(56, 56, 56, 0.3);
}
.my-account .button-normal:first-child:hover {
  border: 1px solid #333;
  color: #333;
}
.my-account .button-normal-light {
  margin-left: 20px;
  background-color: #106ebe;
  border-color: #106ebe;
  color: #fff;
}
.my-account .button-normal-light:hover {
  background-color: #3dbfcc;
  border-color: #3dbfcc;
}
.my-account .selected {
  top: -6px !important;
  font-size: 12px !important;
}
@media only screen and (max-width: 1200px) {
  .my-account .content {
    width: 100%;
  }
  .my-account .con {
    width: 100%;
  }
  .my-account .container {
    max-width: 1200px;
    width: 100%;
  }
  .my-account .account-image {
    display: none;
  }
  .my-account .menu {
    display: none;
  }
  .my-account .account-msg {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .my-account .content {
    width: 100%;
  }
  .my-account .con {
    width: 100%;
  }
  .my-account .container {
    width: 100%;
  }
  .my-account .account-image {
    width: 50%;
  }
  .my-account .account-image {
    display: none;
  }
  .my-account .menu {
    display: none;
  }
  .my-account .account-msg {
    width: 100%;
  }
}
</style>
