<template>
  <div>
    <div class="my-account">
      <div class="content clearfix">
        <div class="template-menu">
          <div class="menu">
            <div class="mar-menu">
              <ul>
                <li @click="choiceMenus(0)">
                  <div class="list" :class="{ acitve: activeIndex == 0 }">
                    <a :class="{ acitve: activeIndex == 0 }">My Account</a>
                  </div>
                </li>
                <li @click="choiceMenus(1)">
                  <div class="list" :class="{ acitve: activeIndex == 1 }">
                    <a :class="{ acitve: activeIndex == 1 }">Payment</a>
                  </div>
                </li>
                <li @click="choiceMenus(2)">
                  <div class="list" :class="{ acitve: activeIndex == 2 }">
                    <a :class="{ acitve: activeIndex == 2 }">My Files</a>
                  </div>
                </li>
                <li @click="choiceMenus(4)">
                  <div class="list" :class="{ acitve: activeIndex == 4 }">
                    <a :class="{ acitve: activeIndex == 4 }">My Stores</a>
                  </div>
                </li>
                <li @click="choiceMenus(3)">
                  <div class="list" :class="{ acitve: activeIndex == 3 }">
                    <a :class="{ acitve: activeIndex == 3 }">Settings</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <MyFile v-if="activeIndex == 2" />
        <MyAccount v-if="activeIndex == 0" />
        <Payments
          v-if="activeIndex == 1"
          :is-show-add-card="isShowAddCard"
        />
        <Setting v-if="activeIndex == 3" />
        <MyStores v-if="activeIndex == 4" />
      </div>
    </div>
  </div>
</template>
<script>
import MyFile from '../../components/account/MyFile'
import MyAccount from '../../components/account/MyAccount'
import Payments from '../../components/account/Payments'
import Setting from '../../components/account/Settings'
import MyStores from '../../components/account/MyStores'
import updateMetaInfo from '@/utils/meta-info/index.js'
import { mapGetters } from 'vuex'

export default {
  components: {
    MyFile,
    MyAccount,
    Payments,
    Setting,
    MyStores
  },
  data() {
    return {
      activeIndex: 0,
      isShowAddCard: false,
      metas: [
        {
          title: 'Manage Your Account Information with Ease.',
          description: 'Take command of your account with our simple platform. Change your address and contact information with ease.'
        },
        {
          title: 'Simple Payment Methods that Are Both Convenient And Secure',
          description: 'Using our secure payment options, you can shop with confidence. You can reduce order delays caused by nonpayment by adding or scheduling automatic payments.'
        },
        {
          title: 'View And Modify Files that Have Been Organized',
          description: 'Keep track of all your product designs. Use My Files and make changes to keep your files organized and easily accessible.'
        },
        {
          title: 'You Can Easily Manage Your Settings.',
          description: 'Is the original email and password still valid? Change and add a new email address and password; all other information will remain unchanged.'
        },
        {
          title: 'You Can Easily Manage Your Stores',
          description: 'Connect your e-commerce platform to easily manage one or more of your stores.'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('User', {
      appName: 'getAppName'
    })
  },
  watch: {
    $route(to, from) {
      console.log(to, from)
      this.activeIndex = this.$route.query.type ? this.$route.query.type : 0
    }
  },
  beforeUpdate() {
    if (
      this.$route.query.isShowAddCard === 'true' ||
      this.$route.query.isShowAddCard === true
    ) {
      this.isShowAddCard = true
    } else {
      this.isShowAddCard = false
    }
  },
  mounted() {
    console.log('....' + this.$route.query.type)
    this.activeIndex = this.$route.query.type ? this.$route.query.type : 0
    if (this.$route.query.isShowAddCard) {
      this.isShowAddCard = true
    }
  },
  methods: {
    choiceMenus(val) {
      this.activeIndex = val
      if (this.metas[val]) {
        updateMetaInfo({
          title: this.metas[val].title || `${this.appName} Fulfillment`,
          meta: [
            {
              name: 'keywords',
              content: this.metas[val].keywords || ''
            },
            {
              name: 'description',
              content: this.metas[val].description || ''
            }
          ]
        })
      } else {
        updateMetaInfo({
          title: `${this.appName} Fulfillment`,
          meta: [
            {
              name: 'keywords',
              content: ''
            },
            {
              name: 'description',
              content: ''
            }
          ]
        })
      }
    }
  }
}
</script>
<style>
.template-menu {
  width: 240px;
}
.template-menu .menu {
  width: 100%;
  vertical-align: top;
  padding: 16px;
  font-size: 1rem;
  position: relative;
  margin-bottom: 16px;
  display: inline-block;
}
.template-menu .mar-menu {
  margin-top: 80px;
}
.template-menu ul {
  width: 204px;
}
.template-menu .mar-menu ul li .list {
  text-align: left;
  border-left-color: #106ebe;
  color: #106ebe;
  height: 36px;
  max-width: 188px;
  margin-bottom: 10px;
  border-left: 2px solid transparent;
  cursor: pointer;
}
.template-menu .mar-menu ul li .list:hover {
  background-color: #e5e7e9;
}
.template-menu .mar-menu ul li a:hover {
  color: #106ebe;
}
.template-menu .mar-menu ul li a {
  color: #1b1b1b;
  display: inline-block;
  height: 100%;
  padding: 7px 19px;
  width: 100%;
  line-height: 27px;
}
.template-menu .mar-menu ul li .list.acitve {
  border-left: 2px solid #106ebe;
  color: #106ebe;
}
.template-menu .mar-menu ul li a.acitve {
  color: #106ebe;
}
.my-account {
  position: relative;
  min-height: 100vh;
  margin: auto;
  background-color: #f7f7f7;
}
.my-account .content {
  max-width: 1168px;
  display: flex;
  margin: auto;
}
/* @media only screen and (max-width: 1200px) {
  .template-menu {
    display: none;
  }
} */
</style>
