<template>
  <div class="store-content">
    <div class="file-title">
      My Stores
      <a-button type="primary" @click="addStore">Add Store</a-button>
    </div>
    <div class="store-con">
      <a-radio-group v-model="value" @change="onChange">
        <div v-for="item in storeList" :key="item.shopId" class="store-list">
          <a-radio :value="item.shopId" />
          <img class="store-logo" src="../../assets/img/store.png">
          <div class="store-name">
            <div class="name ellipsis">{{ item.name }}</div>
            <!-- <div class="time">24h</div> -->
          </div>
          <div class="store-counters">
            <div class="order">
              <p>{{ item.orderNum }}</p>
              <p>Orders</p>
            </div>
            <div class="order">
              <p>{{ item.productNum }}</p>
              <p>Products</p>
            </div>
          </div>
          <div v-if="!item.isConnect" class="connect">
            <a-button type="primary" @click="handConnect(item.shopId)">
              Connect
            </a-button>
          </div>
          <div class="store-icon">
            <a-icon
              type="setting"
              class="set"
              theme="filled"
              @click="
                () =>
                  callNewPage({
                    path: '/store-name',
                    query: { name: item.name, shopId: item.shopId }
                  })
              "
            />
            <a-dropdown :trigger="['click']">
              <a-icon
                type="more"
                class="more"
                @click="e => e.preventDefault()"
              />
              <a-menu slot="overlay">
                <a-menu-item
                  key="0"
                  :disabled="storeList.length <= 1 || item.productNum === 0"
                  @click="copProduct(item.productNum, item.shopId)"
                >
                  Copy products
                </a-menu-item>
                <a-menu-item
                  key="1"
                  :disabled="!item.isConnect || item.type == 2"
                  @click="handDisconnect(item.shopId)"
                >
                  Disconnect
                </a-menu-item>
                <a-menu-item
                  key="2"
                  :disabled="item.type == 2"
                  @click="removeStores(item.shopId)"
                >
                  Remove store
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item
                  key="3"
                  :disabled="!item.url"
                  @click="visitStore(item.url)"
                >
                  Visit store
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
      </a-radio-group>
    </div>
  </div>
</template>
<script>
import { shopList, disconnect, removeStore, changeShop } from '@/api/woocom'
import { mapState, mapMutations, mapGetters } from 'vuex'
export default {
  computed: {
    ...mapState({
      MerchantInfo: state => state.User.merchantInfo
    }),
    ...mapGetters('User', {
      appName: 'getAppName'
    })
  },
  data() {
    return {
      value: 0,
      storeList: []
    }
  },
  async mounted() {
    if (this.MerchantInfo.currentShop) {
      this.value = this.MerchantInfo.currentShop.shopId
    }
    this.getShopList()
  },
  methods: {
    ...mapMutations('User', ['merchantShopMutations']),
    async getShopList() {
      const { data } = await shopList()
      this.storeList = data.records
      // await shopList();
    },
    // 复制产品
    async copProduct(productNum, shopId) {
      console.log('ffff')
      const _this = this
      this.$confirm({
        title: `Copy ${productNum} products to …`,
        content:
          'Due to sales channel limitations some sales channel specific product details like product description text formating and tags might be lost in copy process',
        async onOk() {
          _this.callNewPage({
            path: '/copyProduct',
            query: {
              shopId: shopId,
              productNum: productNum,
              item: JSON.stringify(_this.storeList)
            }
          })
        },
        onCancel() {}
      })
    },
    // 更换店铺
    async onChange(e) {
      console.log(e)
      const { code } = await changeShop({ shopId: e.target.value })
      if (code === 200) {
        this.changeShopMsg(e.target.value)
        console.log(1)
      }
    },
    // 取消链接
    async handDisconnect(id) {
      const _this = this
      var content = `
          ${this.appName} account won’t receive incoming orders for published
          products in your store.\n
          You won’t be able to update products in your store from your
          ${this.appName} account.
        `

      this.$confirm({
        title: 'Disconnect Your store?',
        content: content,
        async onOk() {
          const { code } = await disconnect({ shopId: id })
          if (code === 200) {
            await _this.getShopList()
            await _this.changeShopMsg(id)
          }
        },
        onCancel() {}
      })
    },
    // 添加店铺
    async addStore() {
      this.callNewPage('/changeShop')
    },
    // 链接店铺
    async handConnect(shopId) {
      this.callNewPage({ path: '/changeShop', query: { shopId: shopId }})
    },
    // 删除店铺
    async removeStores(shopId) {
      const _this = this
      var title = `Remove store from ${this.appName}?`
      this.$confirm({
        title: title,
        content:
          'When clicked the OK button, this dialog will be closed after 1 second',
        async onOk() {
          const { code } = await removeStore({ shopId })
          if (code === 200) {
            _this.getShopList()
          } else {
            _this.$message.error('Remove fail')
          }
        },
        onCancel() {}
      })
    },
    // 修改缓存中当前店铺信息
    changeShopMsg(shop_id) {
      const storeList = this.storeList
      for (let i = 0; i < storeList.length; i++) {
        if (shop_id === storeList[i].shopId) {
          const param = {
            name: storeList[i].name,
            shopId: storeList[i].shopId,
            shopType: storeList[i].type
          }
          this.merchantShopMutations(param)
          break
        }
      }
    },
    visitStore(url) {
      if (url.indexOf('http') === 0) {
        window.open(url)
      } else {
        const urls = 'https://' + url
        window.open(urls)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.file-title {
  font-family: CerebriSans, sans-serif;
  font-weight: 500;
  line-height: 1.19;
  font-size: 33px;
  letter-spacing: -1px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}
.store-content {
  width: 884px;
}
.store-list {
  display: flex;
  padding: 18px 32px 21px 32px;
  width: 884px;
  background: white;
  align-items: center;
  margin-bottom: 16px;
  &:hover {
    box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.5);
  }
  p {
    margin: 0;
  }
  .store-logo {
    width: 40px;
    height: 40px;
    margin: 0 15px;
  }
  .store-name {
    max-width: 250px;
    flex: 1;
    .name {
      font-size: 16px;
      color: #000;
      font-weight: bold;
    }
    .time {
      font-size: 13px;
    }
  }
  .store-counters {
    width: 25%;
    .order {
      width: 50%;
      float: left;
      p {
        font-size: 16px;
      }
      p:nth-child(2) {
        font-size: 13px;
      }
    }
  }
  .store-icon {
    margin-left: auto;
    .set {
      font-size: 25px;
    }
    .more {
      transform: rotate(90deg);
      font-size: 30px;
      margin-left: 12px;
    }
  }
}
@media only screen and (max-width: 960px) {
  .store-content {
    width: 734px;
  }
  .store-list {
    display: flex;
    padding: 18px 32px 21px 32px;
    width: 734px;
    background: white;
    align-items: center;
    margin-bottom: 16px;
  }
}
</style>
