<template>
  <div class="payment">
    <div class="container">
      <p class="title">Payment</p>
      <div class="con">
        <div class="payments">
          <a-affix :offset-top="top">
            <a-alert
              v-if="successAlertTip"
              message="Success Tips"
              :description="successAlertMsg"
              type="success"
              show-icon
            />
            <a-alert
              v-if="errorAlertTip"
              message="Error"
              :description="errorAlertMsg"
              type="error"
              show-icon
            />
          </a-affix>
          <div class="title2">Add your credit card</div>
          <p class="title-msg">
            The Credit card you add here will be used to automatically pay for
            processing new orders.
          </p>
          <a-button
            v-if="!payInfo.bindCard"
            style="width: 240px;"
            type="primary"
            size="large"
            @click="outerVisible = true"
          >Add credit card</a-button>
          <div v-if="payInfo.bindCard">
            <a-row :gutter="16">
              <a-col :span="8">
                <a-card title="Card number" :bordered="false" class="cardBg">
                  <p>{{ payInfo.cardNo }}</p>
                  <div class="card-msg">
                    <div class="card-left">
                      Card Holder name
                      <h4>{{ cardOwner }}</h4>
                    </div>
                    <div class="card-right">
                      Expiry date
                      <h4>{{ cardExpDate }}</h4>
                    </div>
                  </div>
                </a-card>
              </a-col>
            </a-row>
          </div>
          <div v-if="payInfo.bindCard" class="card-btn">
            <a-button @click="outerVisible = true">Rebinding</a-button>
            <a-button type="danger" @click="removeCard">Delete</a-button>
          </div>
          <p class="title2">Select the store's time zone</p>
          <div class="input-label-wrap input-label-wrap-select">
            <select id="country" v-model="timeZone" type="text" name="country">
              <option
                v-for="(item, index) in timeZoneList"
                :key="index"
                :value="item.id"
              >{{ item.name }}</option>
            </select>
            <label ref="country" for="country" class="selected">Timezoon</label>
            <span class="message" />
          </div>
          <p class="title2">Automatically process order</p>
          <a-radio-group
            v-model="value"
            :default-value="payInfo.autoPayProcessType"
            @change="radioChangeOne"
          >
            <div class="mar20">
              <a-radio :value="2">
                Automatically process orders after
                <a-input-number
                  v-model="num"
                  controls-position="right"
                  :min="1"
                  :max="10000"
                  @change="ChangeHour"
                />hours when received new order
              </a-radio>
            </div>
            <div class="mar20">
              <a-radio :value="3">
                Automatically process all pending orders daily at
                <a-time-picker
                  v-model="time"
                  :default-value="moment(timeHour.toString(), 'HH:mm')"
                  format="HH:mm"
                  @change="changeTime"
                />
              </a-radio>
            </div>
            <div class="mar20">
              <a-radio :value="1">Manually process orders</a-radio>
            </div>
          </a-radio-group>
          <p v-if="ShopType === 1" class="title2">
            Automatically process risky orders
          </p>
          <a-radio-group v-if="ShopType === 1" v-model="orderRadio">
            <div class="mar20">
              <a-radio :value="1">Automatically process risky orders</a-radio>
            </div>
            <div class="mar20">
              <a-radio :value="2">Manually process risky orders</a-radio>
            </div>
          </a-radio-group>
          <p class="title2">Choose logistics mode</p>
          <a-radio-group v-model="logisticRadio">
            <div class="mar20">
              <a-radio
                :value="1"
              >All orders are shipped via Standard Delivery by
                default.</a-radio>
            </div>
            <div class="mar20">
              <a-radio
                :value="2"
              >All orders are shipped via Express Delivery by
                default.</a-radio>
            </div>
            <div class="tips">
              When certain products cannot be shipped by Express to specific
              countries/districts, we'll ship them by Standard Delivery
            </div>
          </a-radio-group>
          <div style="text-align: center">
            <a-button
              size="large"
              style="width:200px"
              type="primary"
              :loading="iconLoading"
              @click="save"
            >Save</a-button>
          </div>
          <AddCard v-show="outerVisible || isShowAddCard" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AddCard from '../../components/AddCard'
import { timezones } from '@/api/public'
import { payMsg, updatePayMsg } from '@/api/pay'
import { unbindCard } from '@/api/merchant'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  components: {
    AddCard
  },
  computed: {
    ...mapState({
      ShopType: state => state.User.merchantInfo.currentShop.shopType
    })
  },
  props: {
    isShowAddCard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      top: 50,
      successAlertMsg: '', // alert成功提示
      successAlertTip: false, // alert成功提示
      errorAlertTip: false,
      errorAlertMsg: '',
      timeZoneList: [],
      value: 1,
      outerVisible: false, // 添加银行卡弹框
      timeZone: 1,
      num: '',
      time: '',
      payInfo: {}, // 支付信息
      timeHour: '00:00',
      autoRiskProcessType: 1,
      autoPayProcessType: 1,
      autoPayFixTime: '',
      defaultLogisticsType: 1,
      iconLoading: false,
      cardOwner: '',
      logisticRadio: 1,
      orderRadio: 1
    }
  },
  async mounted() {
    {
      const { data } = await timezones()
      this.timeZoneList = data.timeZoneInfoList
      this.timeZone = 24
    }
    this.getPayMsg()
  },
  methods: {
    async getPayMsg() {
      const { data } = await payMsg()
      this.payInfo = data
      this.logisticRadio = data.defaultLogisticsType
      this.orderRadio = data.autoRiskProcessType
      if (data.timeZoneId) {
        this.timeZone = data.timeZoneId
      }
      this.value = data.autoPayProcessType
      this.autoRiskProcessType = data.autoRiskProcessType
      this.autoPayProcessType = data.autoPayProcessType
      this.num = data.autoPayPerHours
      this.defaultLogisticsType = data.defaultLogisticsType
      this.cardOwner = data.cardOwner
      this.cardExpDate = data.cardExpDate
      if (data.autoPayProcessType === 2) {
        this.num = data.autoPayPerHours
      } else if (data.autoPayProcessType === 3) {
        this.timeHour = data.autoPayFixTime
      }
    },
    moment,
    // 解绑银行卡
    async removeCard() {
      const { code } = await unbindCard()
      if (code === 200) {
        this.payInfo.bindCard = false
      }
    },
    async recaptcha() {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('login')
      this.token = token
    },
    changeTime(time, timeString) {
      this.autoPayFixTime = timeString
    },
    changeCheck(e) {
      console.log(e)
    },
    radioChangeThree(e) {
      this.defaultLogisticsType = e.target.value
    },
    // 第二组单选框
    radioChangeTwo(e) {
      this.autoRiskProcessType = e.target.value
    },
    // 第一组单选框
    radioChangeOne(e) {
      this.autoPayProcessType = e.target.value
      this.timeHour = e.target.value
    },
    animateForm(name) {
      if (!this[name]) {
        this.$refs[name].style.top = '-6px'
        this.$refs[name].style.fontSize = '12px'
      }
    },
    animateFormBlur(name) {
      if (this[name] === '') {
        this.$refs[name].style.top = '14px'
        this.$refs[name].style.fontSize = '16px'
      }
    },
    // 改变小时
    ChangeHour(e) {
      this.num = e.target.value
    },
    async save() {
      this.iconLoading = true
      console.log(this.time)
      const _this = this
      if (this.timeHour === 2 && this.num === '') {
        this.$message.warning('please input fix time')
        return
      } else if (this.timeHour === 3 && this.autoPayFixTime === '') {
        this.$message.warning('please input fix time')
        return
      }
      const { data, code } = await updatePayMsg({
        timeZoneId: this.timeZone,
        autoPayProcessType: this.autoPayProcessType,
        autoRiskProcessType: this.orderRadio,
        autoPayPerHours: this.num,
        autoPayFixTime: this.autoPayFixTime,
        defaultLogisticsType: this.logisticRadio
      })
      console.log(data)
      if (code === 200) {
        this.iconLoading = false
        _this.successAlertTip = true
        _this.successAlertMsg = 'Saved successfully'
        setTimeout(() => {
          _this.successAlertTip = false
          _this.successAlertMsg = ''
        }, 3000)
      } else {
        this.iconLoading = false
        _this.errorAlertTip = true
        _this.errorAlertMsg = 'Saved failed'
        setTimeout(() => {
          _this.errorAlertTip = false
          _this.errorAlertMsg = ''
        }, 3000)
      }
    }
  }
}
</script>
<style scope lang="scss">
.payment {
  max-width: 884px;
  vertical-align: top;
  padding: 16px;
  font-size: 1rem;
  position: relative;
  margin-bottom: 16px;
  box-sizing: border-box;
  text-align: left;
  flex-grow: 1;
  .cardBg {
    background: url("../../assets/img/card.svg");
    background-size: 100%;
    width: 350px;
    height: 209px;
    position: relative;
    color: white;
    .ant-card-head-title {
      color: white;
    }
    .card-msg {
      position: absolute;
      bottom: 15px;
      width: 100%;
      display: flex;
      color: white;
      .card-right {
        margin-left: 30px;
      }
      h4 {
        color: white;
      }
    }
  }
  .card-btn {
    margin-top: 10px;
    button {
      margin-right: 20px;
    }
  }
  .payments {
    .tips {
      font-size: 12px;
      color: #ccc;
      margin-bottom: 20px;
      margin-left: 24px;
    }
    max-width: 884px;
    vertical-align: top;
    padding: 16px;
    font-size: 1rem;
    position: relative;
    margin-bottom: 16px;
    box-sizing: border-box;
    text-align: left;
    flex-grow: 1;
  }
  .container {
    max-width: 884px;
    vertical-align: top;
    padding: 16px;
    font-size: 1rem;
    position: relative;
    margin-bottom: 16px;
    box-sizing: border-box;
  }
  .title {
    font-family: CerebriSans, sans-serif;
    font-weight: 500;
    line-height: 1.19;
    font-size: 33px;
    letter-spacing: -1px;
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
  }
  .con {
    position: relative;
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(133, 123, 123, 0.25),
      0 2px 3px hsla(0, 0%, 58%, 0.15), 0 0 2px hsla(0, 0%, 58%, 0.1);
    background-color: #fff;
    color: #383838;
    text-align: left;
    padding: 8px 16px;
    font-size: 0;
    box-sizing: border-box;
  }
  .title2 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 30px;
  }
  .title-msg {
    font-size: 16px;
    margin-top: 20px;
  }
  .input-label-wrap {
    margin: 20px 0 0;
    position: relative;
  }
  .input-label-wrap-select {
    display: inline-block;
    width: 48%;
  }
  .input-label-wrap-marleft {
    margin-left: 4%;
  }
  .input-label-wrap input,
  select {
    margin-bottom: 0;
    width: 100%;
    max-width: 100%;
    height: 48px;
    min-height: 44px;
    padding: 13px;
    line-height: 48px;
    font: inherit;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    outline: none;
    background-color: #fff;
    box-sizing: border-box;
    cursor: text;
    border-radius: 5px;
  }

  /* .input-label-wrap input:focus + label {
    top: -6px;
    font-size: 12px;
  } */
  .input-label-wrap label {
    position: absolute;
    left: 10px;
    padding: 0 4px;
    line-height: 1;
    background: #fff;
    white-space: nowrap;
    top: 14px;
    color: rgba(56, 56, 56, 0.5);
    font-size: 16px;
    cursor: text;
    transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear; /* Safari */
  }
  .input-label-wrap span {
    position: absolute;
    top: 44px;
    left: 14px;
    line-height: 18px;
    text-align: left;
    color: rgba(56, 56, 56, 0.5);
    font-size: 14px;
  }
  .actions {
    margin: 30px 0 20px;
    text-align: right;
  }
  .button-normal {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    -webkit-appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    -webkit-transition: 0.1s;
    transition: 0.1s;
    font-weight: 500;
    padding: 12px 20px;
    border-radius: 4px;
    width: 140px;
    text-align: center;
    font-size: 18px;
    color: rgba(56, 56, 56, 0.6);
    border: 1px solid rgba(56, 56, 56, 0.3);
  }
  .button-normal:first-child:hover {
    border: 1px solid #333;
    color: #333;
  }
  .button-normal-light {
    margin-left: 20px;
    background-color: #106ebe;
    border-color: #106ebe;
    color: #fff;
  }
  .button-normal-light:hover {
    background-color: #3dbfcc;
    border-color: #3dbfcc;
  }
  .selected {
    top: -6px !important;
    font-size: 12px !important;
  }
  .el-input-number {
    width: 100px;
  }
  .el-input__inner {
    height: 34px;
    line-height: 34px;
  }
  .el-input-number.is-controls-right .el-input-number__increase,
  .el-input-number.is-controls-right .el-input-number__decrease {
    line-height: 16px;
  }
  .mar20 {
    margin-top: 20px;
  }
  .el-dialog__header:before {
    background: url("../../assets/img/add-billing.svg");
    background-size: 100%;
    position: absolute;
    top: 20px;
    left: 20px;
    content: "";
    width: 38px;
    height: 38px;
  }
  .el-dialog__header {
    padding-left: 70px;
    padding-top: 30px;
  }
  .el-dialog__header > span {
    font-weight: bold;
  }
  .el-dialog__body {
    padding: 0;
  }
  .card {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;
    .card-title {
      padding: 25px;
    }
    .member {
      background: #f5f6f7;
      padding: 20px;
      .inputcard {
        border: 2px solid #e6eaed;
        border-radius: 5px;
        height: 38px;
        background: white;
        box-sizing: border-box;
        padding: 6px 15px;
        margin-top: 10px;
        input {
          font-size: 16px;
          height: 23px;
          float: left;
          border-style: none;
          appearance: textfield;
          -webkit-appearance: none;
          &:focus {
            outline: none;
          }
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        i {
          margin-right: 12px;
          float: left;
        }
      }
      & > p {
        font-size: 12px;
      }
      .addpwd {
        margin-top: 10px;
        font-size: 14px;
        color: #1261ff;
        span {
          font-weight: bold;
          margin-left: 10px;
        }
      }
    }
    .form {
      margin-top: 25px;
      .box2 {
        width: 43%;
        float: left;
      }
      .box {
        padding: 0px 30px;
        .form-title {
          font-size: 12px;
          color: #6b6c6f;
        }
        input {
          border-radius: 8px;
          border: 2px solid #e6eaed;
          margin-top: 10px;
          width: 100%;
          height: 38px;
          display: block;
        }
        .form-msg {
          font-size: 12px;
          color: red;
          margin-top: 10px;
        }
      }
    }
  }
  .btn {
    margin: 30px;
    span {
      border: 2px solid #ddd;
      background: #fcfdfe;
      color: #6b6c6f;
      cursor: pointer;
      font-size: 14px;
      line-height: 1.75;
      font-family: proxima_nova;
      box-shadow: 0 1px 2px rgba(41, 44, 68, 0.05);
      outline: 0 !important;
      white-space: normal;
      padding: 10px 20px;
      border-radius: 6px !important;
      font-weight: 500;
      &:hover {
        border: 2px solid #409eff;
      }
    }
    em {
      background: #409eff;
      color: #6b6c6f;
      cursor: pointer;
      font-size: 14px;
      line-height: 1.75;
      font-family: proxima_nova;
      box-shadow: 0 1px 2px rgba(41, 44, 68, 0.05);
      outline: 0 !important;
      white-space: normal;
      padding: 10px 20px;
      border-width: 2px;
      border-radius: 6px !important;
      font-weight: 500;
      float: right;
      color: white;
      font-style: normal;
    }
  }
}
</style>
