<template>
  <div class="settings">
    <div class="container">
      <p class="title">Settings</p>
      <div class="con">
        <a-form-model
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          v-bind="layout"
        >
          <a-form-model-item has-feedback label="Email" prop="email">
            <a-input v-model="ruleForm.email" size="large" autocomplete="off" />
          </a-form-model-item>
          <a-form-model-item has-feedback label="Current password" prop="pass">
            <a-input
              v-model="ruleForm.pass"
              size="large"
              type="password"
              autocomplete="off"
            />
          </a-form-model-item>
          <a-form-model-item has-feedback label="New password" prop="checkPass">
            <a-input
              v-model="ruleForm.checkPass"
              size="large"
              type="password"
              autocomplete="off"
            />
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button
              :loading="iconLoading"
              type="primary"
              @click="submitForm('ruleForm')"
            >Submit</a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { UpdatePwd } from '../../api/user'
export default {
  data() {
    const validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the Email'))
      } else {
        if (!/^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/.test(value)) {
          callback(new Error('Please enter a valid email address'))
        } else if (this.ruleForm.checkEmail !== '') {
          this.$refs.ruleForm.validateField('checkEmail')
        }
        callback()
      }
    }
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password'))
      } else {
        if (value.length < 6) {
          callback('Length not less than 6')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password again'))
      } else {
        if (value.length < 6) {
          callback('Length not less than 6')
        }
        callback()
      }
    }
    return {
      iconLoading: false,
      ruleForm: {
        pass: '',
        checkPass: '',
        email: ''
      },
      rules: {
        email: [{ validator: validateEmail, trigger: 'change' }],
        pass: [{ validator: validatePass, trigger: 'change' }],
        checkPass: [{ validator: validatePass2, trigger: 'change' }]
      },
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 }
      }
    }
  },
  computed: {
    ...mapState({
      MerchantInfo: state => state.User.merchantInfo
    })
  },
  mounted() {
    this.ruleForm.email = this.MerchantInfo.username
  },
  methods: {
    async submitForm(formName) {
      const _this = this
      this.$refs[formName].validate(valid => {
        if (valid) {
          _this.updatePassword()
        } else {
          return false
        }
      })
    },
    async updatePassword() {
      this.iconLoading = true
      const { code, msg } = await UpdatePwd({
        oldPassword: this.ruleForm.pass,
        newPassword: this.ruleForm.checkPass
      })
      if (code === 200) {
        this.$message.success('success')
      } else {
        this.$message.error(msg)
      }
      this.iconLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.settings {
  width: 100%;
  .container {
    width: 100%;
    vertical-align: top;
    padding: 16px;
    font-size: 1rem;
    position: relative;
    margin-bottom: 16px;
    box-sizing: border-box;
    .title {
      font-family: CerebriSans, sans-serif;
      font-weight: 500;
      line-height: 1.19;
      font-size: 33px;
      letter-spacing: -1px;
      margin: 20px 0;
      display: flex;
      justify-content: space-between;
    }
    .con {
      position: relative;
      border-radius: 3px;
      box-shadow: 0 1px 3px rgba(133, 123, 123, 0.25),
        0 2px 3px hsla(0, 0%, 58%, 0.15), 0 0 2px hsla(0, 0%, 58%, 0.1);
      background-color: #fff;
      color: #383838;
      text-align: left;
      padding: 22px 16px;
      font-size: 0;
      box-sizing: border-box;
    }
  }
}
</style>
