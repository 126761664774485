import { apiAxios } from '../utils/request.js'
// 获取stripe配置意向
export const createSetupIntent = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/pay/stripe/createSetupIntent',
    data,
    Istoast,
    Send
  )
}
// 获取stripe公钥
export const publicKey = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/pay/stripe/publicKey', data, Istoast, Send)
}
// 查询stripe配置结果
export const querySetupIntent = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/pay/stripe/querySetupIntent',
    data,
    Istoast,
    Send
  )
}
// 获取商户支付信息
export const payMsg = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/merchant/payment/info',
    data,
    Istoast,
    Send
  )
}
// 获取商户支付信息
export const updatePayMsg = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/merchant/payment/info/update',
    data,
    Istoast,
    Send
  )
}
// paypal 创建支付
export const createPaypal = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/pay/paypal/create', data, Istoast, Send)
}
// paypal 执行支付
export const executePaypal = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/pay/paypal/execute', data, Istoast, Send)
}
// stripe/一次性支付 - 创建支付意向
export const createPaymentIntent = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/pay/stripe/createPaymentIntent',
    data,
    Istoast,
    Send
  )
}
// recaptcha验证
export const recaptchaValid = (data, Istoast = false, Send = false) => {
  return apiAxios(
    'post',
    '/shopapi/common/recaptchaValid',
    data,
    Istoast,
    Send
  )
}
// stripe/一次性支付,轮询支付结果
export const createPaymentIntentStatus = (
  data,
  Istoast = false,
  Send = false
) => {
  return apiAxios(
    'post',
    '/shopapi/pay/stripe/createPaymentIntent/status',
    data,
    Istoast,
    Send
  )
}
