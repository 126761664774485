<template>
  <div class="card-mask">
    <div class="card-content">
      <div class="c-title">
        <span>Add a credit card to your account</span>
        <i class="close" @click.stop="closeOrder">
          <a-icon type="close" />
        </i>
      </div>
      <a-alert v-show="isErr" type="error" :message="errorMsg" banner />
      <a-form-model
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        v-bind="layout"
      >
        <div class="c-titles">1. Add a credit card information</div>
        <a-form-model-item has-feedback label="Card details">
          <div id="card-element" />
          <div id="card-errors" class="sr-field-error" role="alert">
            {{ bankError }}
          </div>
        </a-form-model-item>
        <a-form-model-item has-feedback label="Cardholder name" prop="name">
          <a-input v-model="ruleForm.name" type="text" autocomplete="off" />
        </a-form-model-item>
        <div class="c-titles">1. Add a credit card information</div>
        <a-form-model-item has-feedback label="Country" prop="country">
          <a-select v-model="ruleForm.country" autocomplete="off" size="large">
            <a-select-option
              v-for="(item, index) in countrysList"
              :key="index"
              :value="item.countryCode"
            >{{ item.enName }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item has-feedback label="Region" prop="region">
          <a-input v-model="ruleForm.region" type="text" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item has-feedback label="Address1" prop="address1">
          <a-input v-model="ruleForm.address1" type="text" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item
          has-feedback
          label="Address2(option)"
          prop="address2"
        >
          <a-input v-model="ruleForm.address2" type="text" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item has-feedback label="City" prop="city">
          <a-input v-model="ruleForm.city" type="text" autocomplete="off" />
        </a-form-model-item>
        <a-form-model-item has-feedback label="Postal/ZIP Code" prop="zip">
          <a-input v-model="ruleForm.zip" type="text" autocomplete="off" />
        </a-form-model-item>
        <reCaptcha
          :sitekey="recaptchaKey"
          @getValidateCode="getValidateCode"
        />
        <a-form-model-item
          :wrapper-col="{ span: 24 }"
          style="text-align:center; margin-top: 20px"
        >
          <a-button
            id="submit"
            block
            type="primary"
            size="large"
            :loading="iconLoading"
            @click="submit('ruleForm')"
          >
            <div id="spinner" class="spinner hidden" />
            <span id="button-text">Add card</span>
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <div class="bottom1">
        <a-icon type="lock" />
        <span>lockSecure card form</span>
      </div>
      <div class="bottom2">
        <span>Credit cards handled securely with SSL encryption</span>
      </div>
    </div>
  </div>
</template>
<script>
// import Stripe from "../assets/js/stripemin";
// success  4242 4242 4242 4242  05 27 946 88968
// require  4000 0025 0000 3155
// payment  4000 0000 0000 9995
import reCaptcha from '@/components/ReCaptcha'
import {
  createSetupIntent,
  publicKey,
  querySetupIntent,
  recaptchaValid
} from '@/api/pay'
import { countrys } from '@/api/public'
export default {
  components: {
    reCaptcha
  },
  data() {
    return {
      recaptchaKey: process.env.VUE_APP_RECAPTCHAkEY, // recaptcha的key
      isRecaptcha: '', // 验证是否通过
      clientSecret: '',
      key: '',
      stripe: '',
      card: '',
      elements: '',

      ruleForm: {
        card: '',
        name: '',
        country: '',
        region: '',
        address1: '',
        address2: '',
        city: '',
        zip: ''
      },
      countryCode: '',
      rules: {
        card: [
          {
            required: true,
            message: 'Card details required'
          }
        ],
        name: [
          {
            required: true,
            message: 'Cardholder name'
          }
        ],
        country: [
          {
            required: true,
            message: 'Country is required'
          }
        ],
        region: [],
        address1: [
          {
            required: true,
            message: 'address is required'
          }
        ],
        address2: [],
        city: [
          {
            required: true,
            message: 'City is required'
          }
        ],
        zip: [
          {
            required: true,
            message: 'Zip code is required'
          }
        ]
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 }
      },
      iconLoading: false, // 按钮加载中的错误信息
      countrysList: [], // 国家列表
      bankError: '', // 银行卡输入时的错误信息
      isErr: false, // 是否显示提交的错误信息
      errorMsg: '' // 提交的错误信息
    }
  },
  async mounted() {
    {
      const { data } = await countrys()
      this.countrysList = data.countryInfoList
    }
    this.getPublicKey()
  },
  methods: {
    // 获取recaptcha的token
    getValidateCode(value) {
      this.isRecaptcha = value
    },
    // 获取公钥
    async getPublicKey() {
      const { data } = await publicKey()
      this.key = data
      this.getSetupIntent()
    },
    // 获取配置意向
    async getSetupIntent() {
      const { data } = await createSetupIntent()
      this.clientSecret = data.clientSecret
      this.stripeElements()
    },
    stripeElements() {
      // 获取公钥，配置stripe
      // eslint-disable-next-line no-undef
      this.stripe = Stripe(this.key)
      this.elements = this.stripe.elements()
      var style = {
        base: {
          fontSize: '16px',
          color: '#32325d',
          fontFamily:
            '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: 'rgba(0,0,0,0.4)'
          }
        }
      }
      this.card = this.elements.create('card', {
        style: style,
        hidePostalCode: true
      })
      this.card.mount('#card-element')
      // Element focus ring
      this.card.on('focus', function() {
        var el = document.getElementById('card-element')
        el.classList.add('focused')
      })

      this.card.on('blur', function() {
        var el = document.getElementById('card-element')
        el.classList.remove('focused')
      })
    },
    // 这步应该是去轮询查询stripe配置结果
    orderComplete() {
      const _this = this
      this.stripe.retrieveSetupIntent(this.clientSecret).then(function(result) {
        console.log(result)
        _this.queryResult()
        _this.iconLoading = true
      })
    },
    // 提交大stripe，查询配置是否成功
    async queryResult() {
      const _this = this
      setTimeout(async() => {
        const { data } = await querySetupIntent({
          clientSecret: _this.clientSecret
        })
        if (data.status === 1) {
          _this.queryResult()
        } else if (data.status === 2) {
          _this.$parent.outerVisible = false
          _this.$parent.getPayMsg()
          _this.$parent.successAlertTip = true
          _this.$parent.successAlertMsg = 'Credit card added successfully'
          setTimeout(() => {
            _this.$parent.successAlertTip = false
            _this.$parent.successAlertMsg = ''
          }, 3000)
          _this.iconLoading = false
        } else if (data.status === 3) {
          _this.isErrorMsg = data.failReason
          _this.$parent.errorAlertTip = true
          _this.$parent.errorAlertMsg = 'Credit card addition failed'
          _this.iconLoading = false
          setTimeout(() => {
            _this.$parent.errorAlertTip = false
            _this.$parent.errorAlertMsg = ''
          }, 3000)
        }
      }, 500)
    },
    async submit(formName) {
      if (this.isRecaptcha === '') {
        this.$message.error('Please verify reCaptcha')
        return
      }
      await recaptchaValid({
        recaptchaToken: this.isRecaptcha
      })
      const _this = this
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (_this.isRecaptcha === '') {
            this.$message.error('Please verify reCaptcha')
            return
          }
          this.iconLoading = true
          this.stripe
            .confirmCardSetup(this.clientSecret, {
              payment_method: {
                card: this.card,
                billing_details: {
                  address: {
                    city: _this.ruleForm.city,
                    country: _this.ruleForm.country,
                    line1: _this.ruleForm.address1,
                    line2: _this.ruleForm.address2,
                    postal_code: _this.ruleForm.zip,
                    state: _this.ruleForm.region
                  },
                  name: _this.ruleForm.name
                },
                type: 'card'
              }
            })
            .then(function(result) {
              if (result.error) {
                _this.iconLoading = false
                _this.bankError = result.error.message
                window.grecaptcha.reset()
                _this.$message.error('Credit card addition failed.')
              } else {
                // The PaymentMethod was successfully set up
                _this.orderComplete()
              }
            })
        } else {
          return false
        }
      })
    },
    // 关闭弹窗
    closeOrder() {
      this.$parent.outerVisible = false
      if (this.$route.query.isShowAddCard) {
        this.$router.replace({
          path: '/account',
          query: {
            type: 1,
            isShowAddCard: false
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/css/global.css";
.card-mask {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  z-index: 10;
  overflow-y: scroll;
  .card-content {
    width: 640px;
    background: white;
    margin: 0 auto;
    padding: 24px;
    position: relative;
    margin-top: 70px;
    .bottom1 {
      text-align: center;
      font-size: 16px;
      color: #106ebe;
      span {
        margin-left: 10px;
      }
    }
    .bottom2 {
      text-align: center;
      font-size: 13px;
      color: #949494;
    }
    .c-titles {
      margin-bottom: 20px;
      margin-top: 10px;
    }
    .c-title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      span {
        flex: 1;
        font-size: 19px;
      }
      .close {
        color: #333;
        cursor: pointer;
      }
    }
    #card-element {
      border: 1px solid var(--gray-border);
      border-radius: var(--radius);
      padding: 5px 12px;
      height: 44px;
      width: 100%;
      transition: box-shadow 0.2s ease;
      background: white;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      padding-top: 10px;
    }
  }
}
</style>
