<template>
  <div>
    <div class="file-title">
      My Files
      <div class="right">
        <a-upload
          class="upload-demo"
          :show-upload-list="false"
          :custom-request="fnUploadRequest"
          accept=".jpg, .jpeg, .png"
          multiple
          action
        >
          <a-button
            size="large"
            type="primary"
            icon="download"
          >Local file</a-button>
        </a-upload>
      </div>
    </div>
    <div class="myfile">
      <a-input-search
        v-model="page.fileName"
        placeholder="Search for files"
        enter-button="Search"
        size="large"
        @search="searchImg"
      />
      <div class="container">
        <ul
          v-infinite-scroll="load"
          style="overflow: hidden;"
          class="infinite-list"
          infinite-scroll-distance="20"
        >
          <a-empty v-if="imgList.length <= 0" />
          <li
            v-for="(item, index) in imgList"
            :key="item.id"
            @click="choiceProduct(item, index)"
          >
            <div ref="imgs" class="img">
              <img :src="item.smallImageUrl">
            </div>
            <p class="name ellipsis">{{ item.fileName }}</p>
            <p class="size">{{ item.imageWidth }}px×{{ item.imageHeight }}px</p>
          </li>
        </ul>
      </div>
      <div v-show="isMask" class="file-mask">
        <div class="file-content">
          <div class="closed">
            <i class="close" @click="closeMask">
              <a-icon type="close" />
            </i>
          </div>
          <div class="f-img">
            <img :src="imgMsg.fileUrl">
          </div>
          <div class="f-msg">
            <a-input
              v-show="isEdit"
              v-model="fileName"
              size="large"
              placeholder="large size"
              style="margin-bottom: 15px"
              @change="save()"
            />
            <div v-show="!isEdit" class="name">
              {{ imgMsg.fileName }}
              <a-icon type="edit" @click="edit()" />
            </div>
            <div class="size">
              {{ imgMsg.imageWidth }}px × {{ imgMsg.imageHeight }}px /
              {{ imgMsg.fileSize }}
            </div>
            <div class="size">{{ imgMsg.addDate }}</div>
            <a-button size="large" @click="removeImg">Delete</a-button>
          </div>
        </div>
      </div>
    </div>
    <!-- loadding加载 -->
    <div v-show="loading" class="loading">
      <div class="loading-load">
        <a-spin size="large" tip="loading..." />
      </div>
    </div>
  </div>
</template>
<script>
import { fileSts } from '@/api/oss'
import OSS from 'ali-oss'
import { filePage, updateFileInfo, removeFile } from '@/api/file'
import { submitOssFile } from '../../api/file'
import uuid from 'licia/uuid'
export default {
  data() {
    return {
      page: {
        current: 0,
        size: 12,
        fileType: 1,
        fileName: ''
      },
      imgWidth: 0,
      loading: false,
      ossData: {},
      fileName: '',
      imgMsg: {},
      imgList: [],
      isMask: false,
      isEdit: false,
      id: 0,
      index: 0 // 当前编辑的对象
    }
  },
  mounted() {
    this.getOssMsg()
    if (window.screen.width > 1057) {
      this.imgWidth = 191
    } else {
      this.imgWidth = 382
    }
  },
  methods: {
    // 文件上传
    async fnUploadRequest(option) {
      if (option.file.size > 80 * 1024 * 1024) {
        this.$notification.error({ message: 'Maximum file size 80 MB' })
        return
      }
      this.loading = true
      try {
        const _this = this
        const fileSize = this.getFileSize(option.file.size)
        // 获取OSS配置信息
        const client = new OSS({
          endpoint: this.ossData.ossEndpoint,
          accessKeyId: this.ossData.accessKeyId,
          accessKeySecret: this.ossData.accessKeySecret,
          bucket: this.ossData.bucket,
          stsToken: this.ossData.securityToken
        })

        const file = option.file
        const point = file.name.lastIndexOf('.')
        const suffix = file.name.substr(point)
        // let fileName = file.name.substr(0, point);
        // let date = _this.$moment().format("YYYYMMDDHHmmss");
        const uuidValue = uuid()
        const fileNames = `${this.ossData.ossObjectNamePrefix}${uuidValue}${suffix}`
        // 分片上传文件
        const res = await client.multipartUpload(fileNames, file, {
          progress: async function(p) {
            console.log(p)
          },
          headers: {
            'Cache-Control': 'max-age=360000'
          }
        })
        if (res.res.statusCode === 200) {
          // 把他图片路径自带的参数去掉，不然可能会失败
          const imageUrl = this.ossData.domain + '/' + fileNames
          // const imgUrl = res.res.requestUrls[0].split("?");
          const img = new Image()
          img.src = imageUrl
          const imgName = res.name.split('/')
          img.onload = function() {
            _this.submitFile(
              fileSize,
              imgName[2],
              1,
              img.width,
              img.height,
              imageUrl,
              res.bucket,
              res.name
            )
          }
        } else {
          this.$message.error('Upload fail')
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    async submitFile(
      fileSize,
      fileName,
      fileType,
      width,
      height,
      fileUrl,
      bucketName,
      objectName
    ) {
      const { code } = await submitOssFile({
        fileSize: fileSize,
        fileName: fileName,
        fileType: fileType,
        width: width,
        height: height,
        fileUrl: fileUrl,
        bucketName: bucketName,
        objectName: objectName
      })
      if (code === 200) {
        this.page.current = 1
        this.imgList = []
        this.getFilePage()
      }
    },
    // 获取OSS信息
    async getOssMsg() {
      const { data } = await fileSts()
      this.ossData = data
    },
    // 获取文件大小
    getFileSize(file) {
      var fileSize = Math.round(file / 1024)
      var suffix = 'KB'
      if (fileSize > 1000) {
        fileSize = Math.round(fileSize / 1000)
        suffix = 'MB'
      }
      var fileSizeParts = fileSize.toString().split('.')
      fileSize = fileSizeParts[0]
      if (fileSizeParts.length > 1) {
        fileSize += '.' + fileSizeParts[1].substr(0, 2)
      }
      fileSize += suffix
      return fileSize
    },
    // 搜索图片
    searchImg() {
      this.imgList = []
      this.page.current = 1
      this.getFilePage()
    },
    // 选择图片
    async choiceProduct(item, index) {
      this.index = index
      this.id = item.id
      this.isMask = true
      this.imgMsg = item
      this.fileName = item.fileName
    },
    async getFilePage() {
      const { data } = await filePage({
        current: this.page.current,
        size: this.page.size,
        fileType: this.page.fileType,
        fileName: this.page.fileName
      })
      this.loading = false
      if (data.records) {
        this.imgList.push(...data.records)
      }
    },
    // 删除图片
    async removeImg() {
      await removeFile({ fileId: this.id })
      this.imgList.splice(this.index, 1)
      this.isMask = false
      this.isEdit = false
    },
    async save() {
      this.imgList[this.index]['fileName'] = this.fileName
      setTimeout(async() => {
        await updateFileInfo({
          fileId: this.imgMsg.id,
          fileName: this.fileName
        })
      }, 500)
    },
    // 关闭弹窗
    closeMask() {
      this.isMask = false
      this.isEdit = false
    },
    // 编辑图片名称
    async edit() {
      this.isEdit = true
    },
    // 分页
    load() {
      this.page.current += 1
      this.getFilePage()
    }
  }
}
</script>
<style lang="scss" scoped>
.file-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
.file-content {
  position: absolute;
  background: white;
  top: 10%;
  left: 50%;
  height: 608px;
  width: 50%;
  transform: translate(-50%, 0);
  padding: 1%;
  .closed {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .f-msg {
    margin-top: 40px;
    padding: 1%;
    width: 33%;
    float: left;
    position: relative;
    height: 100%;
    button {
      position: absolute;
      bottom: 60px;
      left: 20px;
    }
    .name {
      font-size: 21px;
      word-break: break-all;
      margin-bottom: 15px;
    }
    .size {
      font-size: 14px;
      color: rgba(56, 56, 56, 0.6);
    }
  }

  .f-img {
    width: 66.666%;
    background: #f7f7f7;
    border: 1px solid #ebebeb;
    float: left;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.file-title {
  font-family: CerebriSans, sans-serif;
  font-weight: 500;
  line-height: 1.19;
  font-size: 33px;
  letter-spacing: -1px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}
.myfile {
  float: right;
  width: 884px;
  vertical-align: top;
  padding: 16px;
  font-size: 1rem;
  position: relative;
  margin-bottom: 16px;
  box-sizing: border-box;
  background-color: white;
  margin-top: 10px;
}
.myfile .search {
  position: relative;
}
.myfile .search input {
  padding: 10px 42.5px;
  color: rgba(56, 56, 56, 0.3);
  box-shadow: none;
  transition: all 0.3s ease-in-out;
  display: block;
  width: 100%;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  height: 46px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
  box-sizing: border-box;
  overflow: visible;
  outline: none;
}
.myfile .search input::-webkit-input-placeholder {
  /*Webkit browsers*/
  color: rgba(56, 56, 56, 0.3);
}
.myfile .search input:-moz-placeholder {
  /*Mozilla Firefox 4 to 8*/
  color: rgba(56, 56, 56, 0.3);
}
.myfile .search input::moz-placeholder {
  /*Mozilla Firefox 19+*/
  color: rgba(56, 56, 56, 0.3);
}
.myfile .search input:-ms-input-placeholder {
  /*Internet Explorer 10+*/
  color: rgba(56, 56, 56, 0.3);
}
.myfile input[type="text"]:hover {
  box-shadow: 0px 0px 5px #888888;
}
.myfile input[type="text"]:focus {
  box-shadow: 0px 0px 5px #888888;
}
.myfile .search .search-icon {
  position: absolute;
  left: 20px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  top: 50%;
  transform: translateY(-50%);
}
.myfile .search .delete-icon {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.3;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.container {
  max-width: 1169px;
  margin-right: 0;
  background: white;
  margin-top: 20px;
}
.container ul li {
  width: 25%;
  float: left;
  padding-top: 10px;
  padding-bottom: 20px;
  cursor: pointer;
}
.container ul li:hover {
  background-color: #f7f7f7;
}
.container ul li .img {
  margin: 0 auto;
  width: 90%;
  height: 200px;
  border: 1px solid #ddd;
}
.container ul li .img img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 200px;
}
.container ul li .name {
  margin-top: 5px;
  font-size: 14px;
  color: #949494;
  padding: 0 10px;
  margin: 0;
  padding-top: 10px;
}
.container ul li .size {
  font-size: 14px;
  padding: 0 10px;
  margin: 0;
  padding-top: 10px;
}
@media only screen and (max-width: 960px) {
  .container ul li {
    width: 50%;
  }
}
@media only screen and (max-width: 680px) {
  .container ul li {
    width: 100%;
  }
}
</style>
